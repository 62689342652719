<template>
  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="table-header m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label class="mr-1 mb-0">Show</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :value="perPage"
            :clearable="false"
            :reduce="option => option.value"
            class="per-page-selector d-inline-block ml-50 mr-1"
            @input="updatePerPage"
          />
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <label class="mr-1 mb-0">Search</label>
            <b-form-input
              v-model="searchQuery"
              placeholder="Search"
              class="w-25"
            />
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refFileFoldersListTable"
      :items="fetchFileFoldersList"
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      :sort-by.sync="sortBy"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <!-- Loader -->
      <template #table-busy>
        <TableSpinner />
      </template>

      <!-- Column: Name -->
      <template #cell(label)="data">
        <b-link
          :to="{ name: 'admin-file-folder-details', params: { name: data.item.name, label: data.item.label } }"
        >{{ data.item.label }}</b-link>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <!-- Dropdown -->
        <b-dropdown
          variant="link"
          toggle-class="mx-auto p-0"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item
            :to="{ name: 'admin-file-folder-details', params: { name: data.item.name, label: data.item.label } }"
          >
            <span>Open Folder</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-if="totalItems && perPage !== 'all'"
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination,
  BDropdown, BDropdownItem, BLink,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'

import vSelect from 'vue-select'
import store from '@/store'
import storeModule from '@/views/admin/file-folders/fileFoldersStoreModule'
import useFileFoldersList from '@/views/admin/file-folders/file-folders-list/useFileFoldersList'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import { DEFAULT_PER_PAGE } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BLink,

    vSelect,

    TableSpinner,
  },
  setup(props, { root }) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-file-folders'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchFileFoldersList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      totalItems,
      isSortDirDesc,
      refFileFoldersListTable,

      refetchData,
    } = useFileFoldersList(root)

    return {
      fetchFileFoldersList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      totalItems,
      isSortDirDesc,
      refFileFoldersListTable,

      refetchData,
    }
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.refetchData()
    },
  },
  mounted() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  methods: {
    updatePerPage(val) {
      localStorage.setItem('fileFoldersPerPage', val)
      this.perPage = val
    },
    setFilterParams() {
      const query = { ...this.$route.query }
      const defaultPerPage = localStorage.getItem('fileFoldersPerPage')
      this.currentPage = Number(query.currentPage) || 1
      this.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
      this.searchQuery = query.searchQuery || ''
      this.filterDataLanguage = query.filterDataLanguage || ''
    },
  },
}
</script>

<style lang="scss" scoped>
  .table-header {

    label {
      margin-bottom: 0;
    }
  }

  .badge {
    text-transform: uppercase;
  }

  .per-page-selector {
    width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .table-actions {
    width: 100px;
  }
</style>
